<template>
	<div>
		<form name="juso_form" action="https://www.juso.go.kr/addrlink/addrLinkUrl.do" method="POST">
			<input type="hidden" id="confmKey" name="confmKey" value="U01TX0FVVEgyMDIwMDgyODE0NTAwNjExMDExODU=" >
			<input type="hidden" id="returnUrl" name="returnUrl" :value="retUrl" >
			<input type="hidden" id="resultType" name="resultType" value="4">
		</form>
	</div>
</template>

<script>
export default {
	data(){
		return {			
			retUrl : '',	
		}
	},

	mounted() {
		this.retUrl = location.origin + '/api/mem/juso/jusoResponse';
		setTimeout(this.formSubmit, 1);
	},

	methods : {
		formSubmit() {
			document.juso_form.submit();
		}
	}
}
</script>